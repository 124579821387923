<template>
  <a-modal
    :title="title"
    :visible="open"
    :confirm-loading="submitLoading"
    @ok="submitForm"
    @cancel="cancel"
  >
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="userName" prop="userName">
        <a-input v-model="form.userName" :disabled="true"/>
      </a-form-model-item>
      <a-form-model-item has-feedback label="newPassword" prop="newPassword">
        <a-input-password v-model="form.newPassword" placeholder="Please enter your new password" :maxLength="20" />
      </a-form-model-item>
      <a-form-model-item has-feedback label="confirmPassword" prop="confirmPassword">
        <a-input-password v-model="form.confirmPassword" placeholder="Please confirm the password" :maxLength="20" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { resetUserPwd } from '@/api/system/user'

export default {
  name: 'ResetPassword',
  props: {
  },
  data () {
    const validateNewPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please enter your new password'))
      } else if (!/^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$)([^\u4e00-\u9fa5\s]){5,20}$/.test(value)) {
        callback(new Error('Please enter 5-20 English letters, numbers, or symbols (except Spaces), and contain at least two types of letters, numbers, and punctuation'))
      } else {
        if (this.form.confirmPassword !== '') {
          this.$refs.form.validateField('confirmPassword')
        }
        callback()
      }
    }
    const validateConfirmPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please enter the new password again to confirm'))
      } else if (value !== this.form.newPassword) {
        callback(new Error('The two passwords are different'))
      } else {
        callback()
      }
    }
    return {
      submitLoading: false,
      title: 'reset passwords',
      open: false,
      childrenDrawer: false,
      formLayout: 'horizontal',
      form: {
        userName: undefined,
        newPassword: undefined,
        confirmPassword: undefined
      },
      rules: {
        newPassword: [
          { required: true, validator: validateNewPass, trigger: 'change' }
        ],
        confirmPassword: [
          { required: true, validator: validateConfirmPass, trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.form = {
        id: undefined,
        userName: undefined,
        newPassword: undefined,
        confirmPassword: undefined
      }
    },
    handleResetPwd (row) {
      this.form = {
        id: row.id,
        userName: row.userName
      }
      this.open = true
    },
    /** 重置密码按钮操作 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          resetUserPwd(this.form.id, this.form.newPassword).then(response => {
            this.$message.success(
              'Reset successfully',
              3
            )
            this.open = false
          }).finally(() => {
            this.submitLoading = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
