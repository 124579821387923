<!-- 用户状态标签 -->
<template>
  <div>
    <crud ref="crud" :config="config"  @audit="audit" @auditAll="auditAll"></crud>
    <approve-modal ref='approveModal' @close='auditClose'/>
  </div>
</template>


<script>
import Crud from '@/components/crud/Crud'
import ApproveModal from '@/views/biz/driver/modules/ApproveModal.vue'
import { approveDriverAll } from '@/api/userCar/userCar'

export default {
  name: "index",
  components: {
    Crud,ApproveModal
  },
  data() {
    return {
      config: {
        search: [
          { field: 'nickname', name: 'nickname' },
          { field: 'mobile', name: 'Driver phone' },
        ],
        button: [
          { name: 'One-click audit',  event: 'auditAll', color: 'primary' }
        ],
        table: {
          api: '/user/user-cars/driverPage',
          col: [
            { type: 'index' },
            { field: 'nickname', name: 'nickname' },
            { field: 'mobile', name: 'Driver phone',  },
            { field: 'email', name: 'email',  },
            { field: 'avatar', name: 'avatar', type: 'img', },
            { field: 'taxId', name: 'tax',  },
            { field: 'address', name: 'address',  },
            { field: 'driverAuthStatus', name: 'driver Auth Status' ,type: 'tag',
              tags: [{ name: 'To be reviewed', color: 'yellow' },
                { name: 'pass', color: 'green' },
                { name: 'refuse', color: 'red' },]},
            { field: 'createTime', name: 'create Time'  },
            {
              type: 'button', button: [
                { name: 'audit', event: 'audit' , checkShow: (rowData) => rowData.driverAuthStatus == 0 },
              ]
            },
          ]
        },
      },
    }
  },
  methods: {
    audit(button, rowData) {
      this.$refs.approveModal.audit(rowData);
    },
    auditAll(){
      console.log("======一键审核====")
      let that = this;
      this.$confirm({
        title: 'Review and confirm',
        content: 'Confirm all one-click audit?',
        okText: 'affirm',
        cancelText: 'cancel',
        okType: 'danger',
        onOk: () => {
          approveDriverAll( {}).then((res) => {
            if (res.success) {
              that.$message.success(res.message);
            } else {
              that.$message.warning(res.message)
            }
          }).finally(() => {
            this.$refs.crud.getList();
            that.confirmLoading = false

          })
        },
      });

    },
    auditClose() {
      this.$refs.crud.getList();
    },
  }

}
</script>