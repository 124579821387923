<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="Operating location" prop="areaName">
                <a-input v-model="queryParam.areaName" placeholder="Please enter" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="starting fare" prop="startPrice">
                <a-input v-model="queryParam.startPrice" placeholder="Please enter" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="Starting price free miles" prop="startPriceMileage">
                  <a-input v-model="queryParam.startPriceMileage" placeholder="Please enter" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="Time charge" prop="timeFee">
                  <a-input v-model="queryParam.timeFee" placeholder="Please enter" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="Obsolescence waiting charge" prop="waitFee">
                  <a-input v-model="queryParam.waitFee" placeholder="Please enter" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="Long distance cost miles" prop="distanceFeeMileage">
                  <a-input v-model="queryParam.distanceFeeMileage" placeholder="Please enter" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="Obsolescence waiting charge" prop="distanceFee">
                  <a-input v-model="queryParam.distanceFee" placeholder="Please enter" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="Overnight rate increase percentage" prop="nightPriceIncr">
                  <a-input v-model="queryParam.nightPriceIncr" placeholder="Please enter" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="cancel fee" prop="cancelPrice">
                  <a-input v-model="queryParam.cancelPrice" placeholder="Please enter" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="Predetermined cost" prop="bookPrice">
                  <a-input v-model="queryParam.bookPrice" placeholder="Please enter" allow-clear/>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />query</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />reset</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? 'pack up' : 'unfold' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()"  >
          <a-icon type="plus" />add
        </a-button>

        <a-button type="danger" :disabled="multiple" @click="handleDelete"  >
          <a-icon type="delete" />delete
        </a-button>

        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="isOpen" slot-scope="text, record">
          <a-switch checkedChildren="yes" unCheckedChildren="no" v-model="record.isOpen == 1" @change="changeIsOpen($event,record)"/>
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical"   />
          <a @click="$refs.createForm.handleUpdate(record, undefined)"  >
            <a-icon type="edit" />edit
          </a>
          <a-divider type="vertical"  />
          <a @click="handleDelete(record)"  >
            <a-icon type="delete" />delete
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `common ${total} strip`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageAreaCharge,listAreaCharge, delAreaCharge ,changeIsOpen} from '@/api/areaCharge/areaCharge'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'


export default {
  name: 'AreaCharge',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        areaName: null,
        startPrice: null,
        startPriceMileage: null,
        mileagePriceStep: null,
        timeFee: null,
        waitFee: null,
        distanceFeeMileage: null,
        distanceFee: null,
        nightPriceIncr: null,
        cancelPrice: null,
        bookPrice: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: 'id',
          ellipsis: true,
          customRender: (text, record, index) => index + 1,
          align: 'center'
        },
        {
          title: 'Operating location',
          dataIndex: 'areaName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'Starting price ($)',
          dataIndex: 'startPrice',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'Free miles (km)',
          dataIndex: 'startPriceMileage',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '里程价,越近越贵，越远越便宜，存储数组，比如：1-2 mile :$2.00  2-3 $1.80  3-4 $1.60  4-5 $1.40  5-6 $1.20  &gt;6 $1.15',
        //   dataIndex: 'mileagePriceStep',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: 'Time Fee ($)',
          dataIndex: 'timeFee',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'Outdated waiting fee ($)',
          dataIndex: 'waitFee',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'Distance mileage ($)',
          dataIndex: 'distanceFeeMileage',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'Distance fee ($)',
          dataIndex: 'distanceFee',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'Overnight rate increase percentage',
          dataIndex: 'nightPriceIncr',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'Cancellation Fee ($)',
          dataIndex: 'cancelPrice',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'Booking Fee ($)',
          dataIndex: 'bookPrice',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'Enable or not',
          dataIndex: 'isOpen',
          scopedSlots: { customRender: 'isOpen' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'create time',
          dataIndex: 'createTime',
          // scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: 'operation',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    changeIsOpen (e,record) {
      record.isOpen = e ? 1 : 0
      changeIsOpen(record).then(response => {
        this.$message.success(
          'operate successfully'
        )
        return  record.isOpen = e ? 1 : 0
      })
    },
    /** 查询运营区域计费列表 */
    getList () {
      this.loading = true
     pageAreaCharge(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        areaName: undefined,
        startPrice: undefined,
        startPriceMileage: undefined,
        mileagePriceStep: undefined,
        timeFee: undefined,
        waitFee: undefined,
        distanceFeeMileage: undefined,
        distanceFee: undefined,
        nightPriceIncr: undefined,
        cancelPrice: undefined,
        bookPrice: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: 'Confirm to delete the selected data?',
        content: 'The current selected number is ' + ids + ' data',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delAreaCharge(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                'successfully delete',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: 'Do you confirm export?',
        content: 'This operation exports all data under the current condition instead of the selected data',
        onOk () {
          that.download('user/operating-area-charge/export', {
            ...that.queryParam
          }, `operational_area_billing_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
