<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="Airport name" prop="name">
        <a-input v-model="form.name" placeholder="Please enter" :maxLength='99'/>
      </a-form-model-item>

      <a-form-model-item label="map" prop="position">
<!--        <div id="map" style="width: 100%; height: 800px;"></div>-->
<!--        <button type="primary"></button>-->
        <a-button type="primary" @click="selectOpenPosition">{{form.position ? 'Change position' : 'chosen position'}}</a-button>
      </a-form-model-item>
      <a-form-model-item label="Fence radius (km)" prop="distance">
        <a-input-number v-model="form.distance" style='width: 80%' :min="0" :max="999" />
      </a-form-model-item>

      <a-form-model-item label="location name" prop="position">
        <a-input v-model="form.position" :disabled='true' placeholder="Please enter"/>
      </a-form-model-item>
      <a-form-model-item label="longitude" prop="longitude">
        <a-input v-model="form.longitude" :disabled='true' placeholder="Please enter"/>
      </a-form-model-item>
      <a-form-model-item label="latitude" prop="latitude">
        <a-input v-model="form.latitude" :disabled='true' placeholder="Please enter"/>
      </a-form-model-item>

      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            save
          </a-button>
          <a-button type="dashed" @click="cancel">
            cancel
          </a-button>
        </a-space>
      </div>
    </a-form-model>

    <GoogleMaps ref="GoogleMapscomponents" :gpsName="gpsName" @setMap="shezhiDizhi"/>
  </a-drawer>
</template>

<script>
import {getAirport, addAirport, updateAirport} from '@/api/airport/airport'
import {OSSfileUpload} from '@/utils/request'
import {ACCESS_TOKEN} from '@/store/mutation-types'
import storage from 'store'


let map
let marker
let defaultLatLng = {lat: 13.876016, lng: 100.576993}
let geocoder
import GoogleMaps  from "./GoogleMaps";
export default {
  name: 'CreateForm',
  props: {},
  components: {
    GoogleMaps
  },
  data() {
    return {
      gpsName :'Positioning',
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        name: null,
        position: null,
        longitude: null,
        latitude: null,
        distance: null,
        createTime: null,
        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name: [
          {required: true, message: 'The airport name cannot be empty', trigger: 'blur'}
        ],
        distance :[
          {required: true, message: 'The fence radius cannot be empty', trigger: 'blur'}
        ],
        position :[
          {required: true, message: 'The location cannot be empty', trigger: 'blur'}
        ],
      },
      uploadLoading: false,
      headers: {},
      fileList: [],
      savelat:'',
      savelng:'',
    }
  },
  filters: {},
  created() {
    const token = storage.get(ACCESS_TOKEN)
    this.headers['Authorization'] = 'Bearer ' + token
  },
  computed: {
    uploadAction: function () {
      return OSSfileUpload + '/admin-resource-api/oss/upload'
    }
  },
  watch: {},
  mounted() {
  },
  methods: {
    shezhiDizhi(e) {
      console.log(e+'2222222')
      console.log(e)
      this.form.longitude = e.lng;
      this.$set(this.form,'longitude',e.lng);
      this.form.latitude = e.lat;
      this.$set(this.form,'latitude',e.lat);

      this.form.city = e.city;
      this.$set(this.form,'city',e.city);

      this.form.position = e.address;
      this.$set(this.form,'position',e.address);

    },
    selectOpenPosition() {
      if(this.form.position) {
        this.gpsName = 'Positioning'
      } else {
        this.gpsName = this.form.latitude + ',' + this.form.longitude
      }
      this.$refs['GoogleMapscomponents'].openGoodleMaps();
    },

    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        name: null,
        position: null,
        longitude: null,
        latitude: null,
        distance: null,
        createTime: null,
        remark: null,
      }

    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = 'add'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getAirport({"id": id}).then(response => {
        this.form = response.data;

        this.open = true
        this.formTitle = 'edit'
        // this.initMap()
      })
    },
    initMap() {
      loader
        .load()
        .then((google) => {
          // 缩放视野以包含所有给定的坐标点
          map = new google.maps.Map(document.getElementById("map"),
            {
              // 地图打开时的中心点位，经纬度是数字Number类型的
              center: this.center,
              // 地图缩放参数
              zoom: 17,
              // 最大缩放参数
              maxZoom: 18,
              // 最小缩放参数
              minZoom: 4,
              // 禁用默认UI
              disableDefaultUI: true
            }
          )

          marker = new google.maps.Marker({
            map: map,
            position: defaultLatLng,
            draggable: true
          })
          map.setCenter(marker.getPosition())
          //移动标记点获取经纬度
          google.maps.event.addListener(marker, 'dragend', this.getAreaName)

          //地址搜索初始化
          geocoder = new google.maps.Geocoder()
        })
        .catch((e) => {
          console.log(e)
        })
    },
    getAreaName(result){
      this.savelat=result.latLng.lat()
      this.savelng=result.latLng.lng()
    },
    mapsearch(){
      geocoder.geocode({ 'address': this.mapaddess }, this.GetMapLocation);
    },
    GetMapLocation(results, status){
      if (status === 'OK') {
        map.setCenter(results[0].geometry.location);
        marker.setPosition(results[0].geometry.location);

        this.savelat= results[0].geometry.location.lat();
        this.savelng= results[0].geometry.location.lng();
      } else {
        console.log("error");
      }
    },
    mapsave(){
      var p = marker.getPosition();
      this.savelat=p.lat();
      this.savelng=p.lng();
      this.$emit('mapsave',this.savelng,this.savelat);
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true

          if (this.form.id !== undefined && this.form.id !== null) {
            updateAirport(this.form).then(response => {
              this.$message.success(
                'modify successfully',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addAirport(this.form).then(response => {
              this.$message.success(
                'success',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
