<template>
  <page-header-wrapper>

    <div style="width: 100%;display: flex; padding: 0 30px;">
      <div style="width: 100%">
        <template>
          <div class="page-header-index-wide">

            <a-row :gutter="24">

              <a-col :span="6" :style="{ marginBottom: '24px' }">
                <a-card style="width: 100%">
                  <p v-if="userFlag=='a'">{{ 'Today   |   Added download registered users' }}</p>
                  <p v-if="userFlag=='a'">{{ this.data(1) }}</p>
                  <p v-if="userFlag=='b'">{{ 'Yesterday   |   Added download registered users' }}</p>
                  <p v-if="userFlag=='b'">{{ this.data(2) }}</p>
                  <p v-if="userFlag=='c'">{{ 'Nearly 7 days   |   Added download registered users' }}</p>
                  <p v-if="userFlag=='c'">{{ this.data(3) }}</p>
                  <p v-if="userFlag=='d'">{{ 'About a month   |   Added download registered users' }}</p>
                  <p v-if="userFlag=='d'">{{ this.data(4) }}</p>
                  <p v-if="userFlag=='e'">{{ 'Nearly a year   |   Added download registered users' }}</p>
                  <p v-if="userFlag=='e'">{{ this.data(5) }}</p>
                  <p><font size="6px">{{ userNum }}</font></p>
                  <a-radio-group default-value="a" @change="(e) => updateUser(e.target.value)" button-style="solid">
                    <a-radio-button value="a" style='padding: 0 10px'>
                      Today
                    </a-radio-button>
                    <a-radio-button value="b" style='padding: 0 10px'>
                      Yesterday
                    </a-radio-button>
                    <a-radio-button value="c" style='padding: 0 10px'>
                      Nearly 7 days
                    </a-radio-button>
                    <a-radio-button value="d" style='padding: 0 10px'>
                      About a month
                    </a-radio-button>
                    <a-radio-button value="e" style='padding: 0 10px'>
                      Nearly a year
                    </a-radio-button>
                  </a-radio-group>
                </a-card>
              </a-col>

              <a-col :sm="6"   :span="6" :style="{ marginBottom: '24px' }">
                <a-card style="width: 100%">
                  <p v-if="userFlagH=='ha'">{{ 'Today   |   The client downloads the number of registered users' }}</p>
                  <p v-if="userFlagH=='ha'">{{ this.data(1) }}</p>
                  <p v-if="userFlagH=='hb'">{{ 'Yesterday   |   The client downloads the number of registered users' }}</p>
                  <p v-if="userFlagH=='hb'">{{ this.data(2) }}</p>
                  <p v-if="userFlagH=='hc'">{{ 'Nearly 7 days   |   The client downloads the number of registered users' }}</p>
                  <p v-if="userFlagH=='hc'">{{ this.data(3) }}</p>
                  <p v-if="userFlagH=='hd'">{{ 'About a month   |   The client downloads the number of registered users' }}</p>
                  <p v-if="userFlagH=='hd'">{{ this.data(4) }}</p>
                  <p v-if="userFlagH=='he'">{{ 'Nearly a year   |   The client downloads the number of registered users' }}</p>
                  <p v-if="userFlagH=='he'">{{ this.data(5) }}</p>
                  <p><font size="6px">{{ userNumH }}</font></p>
                  <a-radio-group default-value="ha" @change="(e) => updateUserH(e.target.value)" button-style="solid">
                    <a-radio-button value="ha" style='padding: 0 10px'>
                      Today
                    </a-radio-button>
                    <a-radio-button value="hb" style='padding: 0 10px'>
                      Yesterday
                    </a-radio-button>
                    <a-radio-button value="hc" style='padding: 0 10px'>
                      Nearly 7 days
                    </a-radio-button>
                    <a-radio-button value="hd" style='padding: 0 10px'>
                      About a month
                    </a-radio-button>
                    <a-radio-button value="he" style='padding: 0 10px'>
                      Nearly a year
                    </a-radio-button>
                  </a-radio-group>
                </a-card>
              </a-col>
              <a-col :sm="6"   :span="6" :style="{ marginBottom: '24px' }">
                <a-card style="width: 100%">
                  <p v-if="userFlagP=='pa'">{{ 'Today   |   Driver side download registered users' }}

                  </p>
                  <p v-if="userFlagP=='pa'">{{ this.data(1) }}</p>
                  <p v-if="userFlagP=='pb'">{{ 'Yesterday   |   Driver side download registered users' }}</p>
                  <p v-if="userFlagP=='pb'">{{ this.data(2) }}</p>
                  <p v-if="userFlagP=='pc'">{{ 'Nearly 7 days   |   Driver side download registered users' }}</p>
                  <p v-if="userFlagP=='pc'">{{ this.data(3) }}</p>
                  <p v-if="userFlagP=='pd'">{{ 'About a month   |   Driver side download registered users' }}</p>
                  <p v-if="userFlagP=='pd'">{{ this.data(4) }}</p>
                  <p v-if="userFlagP=='pe'">{{ 'Nearly a year   |   Driver side download registered users' }}</p>

                  <p v-if="userFlagP=='pe'">{{ this.data(5) }}</p>
                  <p><font size="6px">{{ userNumP }}</font></p>
                  <a-radio-group default-value="pa" @change="(e) => updateUserP(e.target.value)" button-style="solid">
                    <a-radio-button value="pa" style='padding: 0 10px'>
                      Today
                    </a-radio-button>
                    <a-radio-button value="pb" style='padding: 0 10px'>
                      Yesterday
                    </a-radio-button>
                    <a-radio-button value="pc" style='padding: 0 10px'>
                      Nearly 7 days
                    </a-radio-button>
                    <a-radio-button value="pd" style='padding: 0 10px'>
                      About a month
                    </a-radio-button>
                    <a-radio-button value="pe" style='padding: 0 10px'>
                      Nearly a year
                    </a-radio-button>
                  </a-radio-group>
                </a-card>
              </a-col>




              <a-col :sm="6"  :span="6" :style="{ marginBottom: '24px' }">
                <a-card style="width: 100%">
                  <p v-if="diominFlag=='a'">
                    {{ 'Today   |   Cumulative number of orders' }}
                  </p>
                  <p v-if="diominFlag=='a'">{{ this.data(1) }}</p>
                  <p v-if="diominFlag=='b'">{{ 'Yesterday   |   Cumulative number of orders' }}</p>
                  <p v-if="diominFlag=='b'">{{ this.data(2) }}</p>
                  <p v-if="diominFlag=='c'">{{ 'Nearly 7 days   |   Cumulative number of orders' }}</p>
                  <p v-if="diominFlag=='c'">{{ this.data(3) }}</p>
                  <p v-if="diominFlag=='d'">{{ 'About a month   |   Cumulative number of orders' }}</p>
                  <p v-if="diominFlag=='d'">{{ this.data(4) }}</p>
                  <p v-if="diominFlag=='e'">{{ 'Nearly a year   |   Cumulative number of orders' }}</p>
                  <p v-if="diominFlag=='e'">{{ this.data(5) }}</p>
                  <p><font size="6px">{{ totalNum }}</font></p>

                  <a-radio-group default-value="a" @change="(e) => updateDiomn(e.target.value)" button-style="solid">
                    <a-radio-button value="a" style='padding: 0 10px'>
                      Today
                    </a-radio-button>
                    <a-radio-button value="b" style='padding: 0 10px'>
                      Yesterday
                    </a-radio-button>
                    <a-radio-button value="c" style='padding: 0 10px'>
                      Nearly 7 days
                    </a-radio-button>
                    <a-radio-button value="d" style='padding: 0 10px'>
                      About a month
                    </a-radio-button>
                    <a-radio-button value="e" style='padding: 0 10px'>
                      Nearly a year
                    </a-radio-button>
                  </a-radio-group>
                </a-card>
              </a-col>

            </a-row>


            <a-row :gutter="24">
              <a-col :sm="24" :style="{ marginBottom: '24px' }">
                <a-card style="width: 100%;box-sizing: border-box">
                  <div style="display:flex;justify-content: space-around;">
                    <div id="myChart" :style="{width: '100%', height: '500px'}"></div>


                  </div>
                </a-card>
              </a-col>
            </a-row>


            <a-row :gutter="24" style=''>
              <a-col :sm="12" :style="{ marginBottom: '24px' }">
                <a-card style="width: 100%">
                  <div id="myChart1" style="width: 100%" :style="{height: '500px'}"></div>
                </a-card>
              </a-col>
              <!--              <a-col :sm="12" :style="{ marginBottom: '24px' }">-->
              <!--                <a-card style="width: 100%" >-->
              <!--                  <div id="myChart3" style="width: 100%" :style="{height: '500px'}"></div>-->
              <!--                </a-card>-->
              <!--              </a-col>-->
            </a-row>

          </div>
        </template>
      </div>
    </div>

  </page-header-wrapper>
</template>

<script>
import {timeFix} from '@/utils/util'
import {mapGetters} from 'vuex'
import {PageHeaderWrapper} from '@/components/ProLayout'
import ruoyiLogo from '@/assets/projects/ruoyi.png'
import antdvLogo from '@/assets/projects/antdv.png'
import * as echarts from 'echarts';
import {
  getInstutionByDay,
  getBuyVipData,
  getUserData,
  getRechargePal,
  getALLCollectNum,
  getDiomnData,
  getVipData,
  getUserDayData,
  getRuioDataByDay,
  getBuyData, getUserLoginData
} from '@/api/biz/user'
import {vipMoney} from '@/api/dashboard/dashboard'
import ChartCard from './ChartCard'
import moment from 'moment';
import {getSmsTotalBarChart, getSmsTotalPieChart} from "@/api/sms/log";

export default {
  name: 'MoneyData',
  components: {
    PageHeaderWrapper,
    ChartCard
  },
  data() {
    return {
      xInstutiondata: [],
      yInstutiondata1: [],

      xInstutiondata1s: [],
      yInstutiondata1s: [],

      xInstutiondata4: [],
      yInstutiondata4: [],
      yInstutiondata4s: [],


      yInstutiondata2: [],
      userFlag: 'a',
      userFlagH: 'ha',
      userFlagP: 'pa',
      userHLoginFlag:'ha',
      userPLoginFlag:'pa',
      diominFlag: 'a',
      vipFlag: 'a',
      buyFlag: 'a',
      xStroredata: [],
      yStroredata1: [],
      yStroredata2: [],

      xUserdata: [],
      yUserdata1: [],
      yUserdata2: [],
      rechargeData: [],

      xdevicedata: [],
      ydevicedata1: [],
      ydevicedata2: [],

      userDayOneData: [],
      userDayTwoData: [],

      totalNum: "0",
      userNum: "0",
      userNumH: "0",
      userNumP: "0",
      userLoginNumH:"0",
      userLoginNumP:"0",
      noNum: "0",
      manNum: "0",
      womanNum: "0",
      dionNum: "0",
      instutionList: [],
      storeList: [],
      loading: false,
      // 贡献者
      contributors: [
        {
          name: 'wangze',
          avatar: 'https://portrait.gitee.com/uploads/avatars/user/1662/4988475_fuzui_1586973704.png!avatar60',
          email: '73400@163.com'
        }
      ],
      // 赞助
      sponsorList: [
        {
          key: 'RuoYi',
          tab: 'RuoYi'
        },
        {
          key: 'Antdv',
          tab: 'Antdv'
        }
      ],
      noTitleKey: 'RuoYi',
      timeFix: timeFix(),
      // 用户信息
      user: {
        dept: {
          deptName: ''
        }
      },
      roleGroup: {},
      postGroup: {},


    }
  },
  computed: {
    ...mapGetters([
      'avatar',
      'nickname'
    ])
  },
  created() {
    this.getUser()
    this.updateUser('a')
    this.updateUserH('ha')
    this.updateUserP('pa')
    this.updateDiomn('a')
    // this.updateVip('a')
    // this.updateBuy('a')
    this.drawLine1()
    // this.drawLine4()
    // this.getALLCollection()
  },
  mounted() {
    this.drawLine1();
    this.drawLine2();
    // this.drawLine3();
    // this.drawLine4();
  },
  methods: {
    updateUser(value) {
      console.log(value)
      this.userFlag = value
      getUserData({
        type: value
      }).then(res => {
        const data = res.data;
        this.userNum = data + "";
      });
    },
    updateUserH(value) {
      console.log(value)
      this.userFlagH = value
      getUserData({
        type: value
      }).then(res => {
        const data = res.data;
        this.userNumH = data + "";
      });
    },
    updateUserP(value) {
      console.log(value)
      this.userFlagP = value
      getUserData({
        type: value
      }).then(res => {
        const data = res.data;
        this.userNumP = data + "";
      });
    },
    getUserLoginDataH(value) {
      console.log(value)
      this.userHLoginFlag = value
      getUserLoginData({
        type: value
      }).then(res => {
        const data = res.data;
        this.userLoginNumH = data + "";
      });
    },
    getUserLoginDataP(value) {
      console.log(value)
      this.userPLoginFlag = value
      getUserLoginData({
        type: value
      }).then(res => {
        const data = res.data;
        this.userLoginNumP = data + "";
      });
    },
    updateDiomn(value) {
      this.diominFlag = value
      getDiomnData({
        type: value
      }).then(res => {
        const data = res.data;
        this.totalNum = data + "";
      });
    },
    updateVip(value) {
      this.vipFlag = value
      getVipData({
        type: value
      }).then(res => {
        const data = res.data;
        this.manNum = data + "";
      });
    },
    updateBuy(value) {
      this.buyFlag = value
      getBuyData({
        type: value
      }).then(res => {
        const data = res.data;

        data.forEach((el) => {
          if (el.type == '1') {
            this.womanNum = el.price + "";
          }
          if (el.type == '2') {
            this.dionNum = el.price + "";
          }
        });
      });
    },
    // 获取用户信息
    getUser() {
      // getUserProfile().then(response => {
      //   window.console.log('response 111', response)
      //   this.user = response.data
      //   this.roleGroup = response.roleGroup
      //   this.postGroup = response.postGroup
      // })
    },
    data(value) {
      if (value === 1) {
        var today = new Date();
        var year = today.getFullYear();//获取年份
        var month = today.getMonth() + 1;//获取月份
        var day = today.getDate();//获取日期
        return year + "-" + month + "-" + day + '~' + year + "-" + month + "-" + day
      }
      if (value === 2) {
        var today = new Date();
        var yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
        return yesterday.getFullYear() + "-" + (yesterday.getMonth() + 1) + "-" + yesterday.getDate() + '~' + yesterday.getFullYear() + "-" + (yesterday.getMonth() + 1) + "-" + yesterday.getDate()
      }

      if (value === 3) {
        var today = new Date();
        var yesterday = new Date(today.getTime() - 144 * 60 * 60 * 1000);
        var yesterdays = new Date(today.getTime());
        return yesterday.getFullYear() + "-" + (yesterday.getMonth() + 1) + "-" + yesterday.getDate() + '~' + yesterdays.getFullYear() + "-" + (yesterdays.getMonth() + 1) + "-" + yesterdays.getDate()
      }
      if (value === 4) {
        var today = new Date();
        var yesterday = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
        var yesterdays = new Date(today.getTime());
        return yesterday.getFullYear() + "-" + (yesterday.getMonth() + 1) + "-" + yesterday.getDate() + '~' + yesterdays.getFullYear() + "-" + (yesterdays.getMonth() + 1) + "-" + yesterdays.getDate()
      }
      if (value === 5) {
        var today = new Date();
        var yesterday = new Date(today.getTime() - 365 * 24 * 60 * 60 * 1000);
        var yesterdays = new Date(today.getTime());
        return yesterday.getFullYear() + "-" + (yesterday.getMonth() + 1) + "-" + yesterday.getDate() + '~' + yesterdays.getFullYear() + "-" + (yesterdays.getMonth() + 1) + "-" + yesterdays.getDate()
      }
    },


    onSponsorTabChange(key, type) {
      this[type] = key
    },
    collectionCount() {
      getInstutionByDay().then(res => {
        if (res.data) {
          const data = res.data;
          data.forEach((el) => {
            const obj = {}
            obj.num = el.price;
            obj.title = el.times;
            this.instutionList.push(obj);
          });
        }
      });
      // /*门店日增*/
      // getStoreByDay().then(res => {
      //   if (res.data){
      //     const data = res.data;
      //     data.forEach((el) => {
      //       const obj = {}
      //       obj.num = el.num;
      //       obj.title = el.time;
      //       this.storeList.push(obj);
      //     });
      //   }
      // });
    },
    getALLCollection() {
      //统计（机构总数量、门店总数量、用户总数量、设备总数量）
      getALLCollectNum().then(res => {
        if (res.data) {
          const data = res.data;
          this.totalNum = data.totalNum + "";
          this.userNum = data.userNum + "";
          this.noNum = data.noNum + "";
          this.manNum = data.manNum + "";
          this.womanNum = data.womanNum + "";
        }
      });
    },

    drawLine1() {
      //获取机构概览
      getInstutionByDay().then(res => {
        this.xInstutiondata = [];
        this.yInstutiondata1 = [];
        this.xInstutiondata1s = [];
        this.yInstutiondata1s = [];
        if (res.data) {
          const data = res.data.orderNum;//  订单量
          data.forEach((el) => {
            this.xInstutiondata.push(el.times);
            this.yInstutiondata1.push(Number(el.nums));
          });
          res.data.orderMoney.forEach((el) => {
            // this.xInstutiondata1s.push(el.times);
            this.yInstutiondata1s.push(Number(el.moneys));
          });
          // debugger
          // console.log(this.xInstutiondata)
          // data1.forEach((el) => {
          //   this.yInstutiondata2.push(Number(el.num));
          // });

          // 基于刚刚准备好的 DOM 容器，初始化 EChart 实例  myChart1
          let myChart = echarts.init(document.getElementById('myChart'));
          var option = {
            title: {
              text: '  Overview of user taxi orders'
            },
            tooltip: {
              trigger: 'item',
              triggerOn: "mousemove",
            },
            legend: {
              data: ['Taxi-booking revenue', 'Number of taxi orders']
            },


            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: this.xInstutiondata
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                name: 'Number of taxi orders',
                type: 'line',
                stack: 'Total',
                data: this.yInstutiondata1
              },
              {
                name: 'Taxi-booking revenue',
                type: 'line',
                stack: 'Total',
                data: this.yInstutiondata1s
              }
            ]
          };
          // 绘制图表
          // myChart.setOption(option);
          option && myChart.setOption(option);
        }
      });

    },

    drawLine2() {
      getRuioDataByDay().then(res => {
        this.dataList = [];
        if (res.data) {
          this.dataList = res.data;//  每日总量
          var chartDom = document.getElementById('myChart1');
          var myChart = echarts.init(chartDom);
          var option;

          option = {
            title: {
              text: '  User download consumption statistics'
            },
            tooltip: {
              trigger: 'item'
            },
            legend: {
              top: '5%',
              left: 'center'
            },
            series: [
              {
                name: 'value',
                type: 'pie',
                radius: ['40%', '70%'],
                label: {formatter:'{b} {c} ({d}%)'},
                itemStyle: {
                  borderRadius: 10
                },


                data: this.dataList
              }
            ]
          };

          option && myChart.setOption(option);

          //1111
        }
      });
    },
    drawLine3() {
      vipMoney().then(res => {
        this.rechargeData = [];
        if (res.data) {
          this.rechargeData = res.data;//  每日总量
          var chartDom = document.getElementById('myChart3');
          var myChart = echarts.init(chartDom);
          var option;
          option = {
            title: {
              text: 'Accumulated member recharge amount statistics'
            },
            tooltip: {
              trigger: 'item'
            },
            legend: {
              top: '5%',
              left: 'center'
            },

            series: [
              {
                name: 'Accumulated member recharge amount',
                type: 'pie',
                radius: ['40%', '70%'],
                label: {formatter:'{b} {c} ({d}%)'},
                itemStyle: {
                  borderRadius: 10
                },


                data: this.rechargeData
              }

            ]
          };

          option && myChart.setOption(option);
        }
      });
    },
    drawLine4(){
      //获取机构概览
      getBuyVipData().then(res => {
        this.xInstutiondata4 = [];
        this.yInstutiondata4 = [];
        this.yInstutiondata4s = [];
        if (res.data) {
          const data = res.data.orderNum;//  订单量
          data.forEach((el) => {
            this.xInstutiondata4.push(el.times);
            this.yInstutiondata4.push(Number(el.nums));
          });
          res.data.orderMoney.forEach((el) => {
            // this.xInstutiondata4.push(el.times);
            this.yInstutiondata4s.push(Number(el.moneys));
          });
          // debugger
          // console.log(this.xInstutiondata)
          // data1.forEach((el) => {
          //   this.yInstutiondata2.push(Number(el.num));
          // });

          // 基于刚刚准备好的 DOM 容器，初始化 EChart 实例  myChart1
          // let myChart4 = echarts.init(document.getElementById('myChart4'));
          // var option = {
          //   title: {
          //     text: '  会员订阅概览'
          //   },
          //   tooltip: {
          //     trigger: 'item',
          //     triggerOn: "mousemove",
          //   },
          //   legend: {
          //     data: ['订阅收入', '订阅数']
          //   },
          //
          //
          //   grid: {
          //     left: '3%',
          //     right: '4%',
          //     bottom: '3%',
          //     containLabel: true
          //   },
          //   toolbox: {
          //     feature: {
          //       saveAsImage: {}
          //     }
          //   },
          //   xAxis: {
          //     type: 'category',
          //     boundaryGap: false,
          //     data: this.xInstutiondata4
          //   },
          //   yAxis: {
          //     type: 'value'
          //   },
          //   series: [
          //     {
          //       name: '订阅数',
          //       type: 'line',
          //       stack: 'Total',
          //       data: this.yInstutiondata4
          //     },
          //     {
          //       name: '订阅收入',
          //       type: 'line',
          //       stack: 'Total',
          //       data: this.yInstutiondata4s
          //     }
          //   ]
          // };
          // 绘制图表
          // myChart.setOption(option);
          // option && myChart4.setOption(option);
        }
      });
    }

  },
}
</script>

<style lang="less" scoped>
@import "./index.less";

blockquote {
  padding: 0 1em;
  color: #6a737d;
  border-left: 0.25em solid #dfe2e5;
}

.project-list {

  .card-title {
    font-size: 0;

    a {
      color: rgba(0, 0, 0, 0.85);
      margin-left: 12px;
      line-height: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: top;
      font-size: 14px;

      &:hover {
        color: #1890ff;
      }
    }
  }

  .card-description {
    color: rgba(0, 0, 0, 0.45);
    height: 66px;
    line-height: 22px;
    overflow: hidden;
  }

  .project-item {
    display: flex;
    margin-top: 8px;
    overflow: hidden;
    font-size: 12px;
    height: 20px;
    line-height: 20px;

    a {
      color: rgba(0, 0, 0, 0.45);
      display: inline-block;
      flex: 1 1 0;

      &:hover {
        color: #1890ff;
      }
    }

    .download {
      color: rgba(0, 0, 0, 0.25);
      flex: 0 0 auto;
      float: right;
    }
  }

  .ant-card-meta-description {
    color: rgba(0, 0, 0, 0.45);
    height: 44px;
    line-height: 22px;
    overflow: hidden;
  }
}

.item-group {
  padding: 20px 0 8px 24px;
  font-size: 0;

  a {
    color: rgba(0, 0, 0, 0.65);
    display: inline-block;
    font-size: 14px;
    margin-bottom: 13px;
    width: 25%;
  }
}

.mobile {

  .project-list {

    .project-card-grid {
      width: 100%;
    }
  }

  .more-info {
    border: 0;
    padding-top: 16px;
    margin: 16px 0 16px;
  }

  .headerContent .title .welcome-text {
    display: none;
  }
}

</style>
