<template>
  <a-modal :title='title' :width='800' :visible='visible' :confirmLoading='confirmLoading' @ok='handleOk' @cancel='close'
           cancelText='关闭'>

    <a-spin :spinning='confirmLoading'>
      <a-form>
        <a-card :bordered="false">
          <detail-list title="Withdrawal information" :col="2">
            <detail-list-item term="Withdrawal amount (RMB)">{{ formData.money }}</detail-list-item>
            <detail-list-item term="Withdrawal fee (RMB)">{{ formData.serviceChargeNum }}</detail-list-item>
            <detail-list-item term="Amount to be transferred (RMB)">{{ formData.realMoney }}</detail-list-item>
            <detail-list-item term="Withdrawal status">{{ formData.status | statusFilter }}</detail-list-item>
            <detail-list-item term="Withdrawal application time">{{ formData.createTime }}</detail-list-item>
          </detail-list>
          <a-divider style="margin-bottom: 32px"/>
          <detail-list title="Collection account information" :col="2">
            <detail-list-item term="account type">{{ formData.withdrawType | typeFilter }}</detail-list-item>
            <detail-list-item term="Account name">{{ formData.accountName }}</detail-list-item>
            <detail-list-item term="Account number">{{ formData.accountNum }}</detail-list-item>
            <detail-list-item term="Receipt QR code"><img-preview   :src=" formData.qrCode" :size="60"  style="margin-right: 8px;" /></detail-list-item>
          </detail-list>

          <template v-if="formData.withdrawType==3">
            <a-divider style="margin-bottom: 32px"/>
            <detail-list title="Receiving bank information" :col="2">
              <detail-list-item term="bank name">{{ formData.bankName }}</detail-list-item>
              <detail-list-item term="bank address">{{ formData.bankAdress }}</detail-list-item>
            </detail-list>

          </template>
        </a-card>
        <a-form-item label="audit result" :labelCol="labelCol" :wrapperCol="wrapperCol">
<!--          <api-select placeholder='请选择审核结果' v-model="formData.status" :option="statusOptions" />-->
          <a-select v-model="formData.status" :disabled='isReadOnly' placeholder="please select">
            <a-select-option :value="0">To be reviewed</a-select-option>
            <a-select-option :value="1">Review and reject</a-select-option>
            <a-select-option :value="2">pass the audit</a-select-option>
            <a-select-option :value="3">cancel</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item v-show="formData.status == 1" label="audit result" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-textarea :rows='3' :readOnly="isReadOnly" placeholder='Please enter the reason for rejection (80 words or less)' v-model="formData.auditOpinion"  :maxlength="80"/>
        </a-form-item>

      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>

import ApiSelect from '@/components/crud/form/ApiSelect.vue'
import { approveWithDraw } from '@/api/user/withdraw'
import DetailList from '@/components/crud/DetailList'
import ImgPreview from '@/components/crud/table/ImgPreview.vue'
const DetailListItem = DetailList.Item;
export default {
  name: 'CrudForm',
  components: {
    ImgPreview,
    ApiSelect,
    DetailList,
    DetailListItem,
  },
  props: {
    statusOptions: {
      type: Array,
      default() {
        return [{ id: '2', name: 'pass the audit' }, { id: '1', name: 'Review and reject' },{ id: '0', name: 'To be reviewed' }];
      }
    }
  },
  data() {
    return {
      title:"audit",
      formData: {
        // id: '',
        // userId: null,
        // status: null,
        // failReason:null
      },           // 表单数据
      visible: false,         // 弹窗显示控制
      confirmLoading: false,  // 提交后加载, 防止短时间内重复提交
      isReadOnly: false,
      labelCol: { xs: { span: 24 }, sm: { span: 5 } },    // 表单项标签宽度配置
      wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }, // 表单项内容宽度配置
    }
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        '0': 'To be reviewed',
        '1': 'Review and reject',
        '2': 'pass the audit'
      };
      return statusMap[status]
    },
    typeFilter(status) {
      const statusMap = {
        1: 'Alipay',
        2: 'we chat',
        3: 'bank card'
      };
      return statusMap[status]
    },

  },
  methods: {
    /**
     * 外部方法, 外部调用此方法 打开编辑表单
     * @param {*} record 数据
     */
    audit(record,isReadOnly) {
      // this.formData.id = record.id;
      // this.formData.userId = record.userId;
      // this.formData.status = null;
      // this.formData.failReason = '';
      this.formData = record
      this.confirmLoading = false;
      if(!isReadOnly){
        this.isShow = false;
        this.isReadOnly = true;
        this.title = "details"
      }
      this.visible = true;
    },

    /**
     * 关闭表单, 触发 close 事件
     */
    close() {
      this.$emit('close')
      this.visible = false
      this.isReadOnly = false;
    },
    /**
     * 提交
     */
    handleOk() {
      if(!this.isReadOnly){
        this.confirmLoading = true;
        if (this.formData.status != '1' && this.formData.status != '2') {
          this.$message.warn("Please select audit results");
          this.confirmLoading = false;
          return;
        }
        if (this.formData.status == '1' && !this.formData.auditOpinion) {
          this.$message.warn("Please enter the reason for the rejection");
          this.confirmLoading = false;
          return;
        }
        approveWithDraw(this.formData).then(res => {
          if (res.success) {
            this.$message.success(res.message);
            this.close()
            this.$emit('ok');
          } else {
            this.$message.warning(res.message)
          }

        }).finally(() => {
          this.submitLoading = false
        })
      }else{
        this.close()
        this.$emit('ok');
      }

    },
  }
}
</script>

<style lang='less' scoped></style>