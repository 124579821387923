<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="nickname" prop="nickname">
                <a-input v-model="queryParam.nickname" placeholder="please enter" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="mobile" prop="mobile">
                <a-input v-model="queryParam.mobile" placeholder="please enter" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="status" prop="status">
                <a-select placeholder="please enter" v-model="queryParam.status" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in dict.type.sys_enable_disable" :key="index" :value="d.value">{{ d.label }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />query</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />reset</a-button>

              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-dropdown :disabled='multiple'>-->
<!--          <a-menu slot="overlay">-->
<!--            <a-menu-item key="1" @click="handleSetLineVip(1)"><a-icon type="setting"/>一键设置线下会员</a-menu-item>-->
<!--            <a-menu-item key="2" @click="handleSetLineVip(2)"><a-icon type="delete"/>取消设置线下会员</a-menu-item>-->
<!--            <a-menu-item key="3" @click="handleSetHighQuality(1)"><a-icon type="setting"/>一键设置高质量用户</a-menu-item>-->
<!--            <a-menu-item key="4" @click="handleSetHighQuality(2)"><a-icon type="delete"/>取消设置高质量用户</a-menu-item>-->
<!--          </a-menu>-->
<!--          <a-button style="margin-left: 8px"> 批量操作 <a-icon type="down" /></a-button>-->
<!--        </a-dropdown>-->


        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :sexOptions="dict.type.sys_user_sex"
        :isNoticeOptions="dict.type.sys_notice_status"
        :statusOptions="dict.type.sys_enable_disable"
        @ok="getList"
      />

      <user-info ref="userInfo" @close="getList"></user-info>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :bordered="tableBordered"
      >
         <span slot="img" slot-scope="text,record">
          <img-preview v-if='record.avatar' :src="record.avatar" style="width: 40px;height: 40px;"/>
           <span v-else>nothing</span>
        </span>
        <span slot="userType" slot-scope="text, record">
          <a-tag  color="green" v-if='text == 1'>user</a-tag>
          <a-tag color="purple" v-if='text == 2'>driver</a-tag>
        </span>
        <span slot="sex" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_user_sex']" :value="record.sex"/>
        </span>
        <span slot="isNotice" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_notice_status']" :value="record.isNotice"/>
        </span>
        <span slot="status" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_enable_disable']" :value="record.status"/>
        </span>
<!--        <span slot="vipExpTime" slot-scope="text, record">-->
<!--          {{ parseTime(record.vipExpTime) }}-->
<!--        </span>-->
<!--        <span slot="createTime" slot-scope="text, record">-->
<!--          {{ parseTime(record.createTime) }}-->
<!--        </span>-->
        <span slot='operation' slot-scope='text, record'>
<!--          <a @click="handleDetail(record)">详情</a>-->
          <a-divider type='vertical'  />
          <a @click='$refs.createForm.handleUpdate(record, undefined)'
          >
            <a-icon type='edit' />edit
          </a>
          <a-divider type='vertical'  />
          <a @click='handleDelete(record)' >
            <a-icon type='delete' />delete
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `common ${total} strip`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageUser,listUser, delUser,setLineVip,unSetLineVip,setHighQuality,unSetHighQuality } from '@/api/biz/user'
import CreateForm from './modules/CreateForm'
import UserInfo from './modules/UserInfo.vue'
import { tableMixin } from '@/store/table-mixin'
import Template from '@/views/sms/template/index.vue'
import ImgPreview from '@/components/crud/table/ImgPreview.vue'

export default {
  name: 'User',
  components: {
    ImgPreview, Template,
    CreateForm,UserInfo
  },
  mixins: [tableMixin],
  dicts: ['sys_user_sex', 'sys_notice_status', 'sys_enable_disable'],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        mobile: null,

        nickname: null,
        sex: null,
        invitationCode: null,
        isNotice: null,
        status: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '#',
          dataIndex: '',
          ellipsis: true,
          align: 'center',
          customRender: function (t, r, index) {
            return parseInt(index) + 1
          }
        },
        {
          title: 'nickname',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'userType',
          dataIndex: 'userType',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'userType' }
        },
        {
          title: 'mobile',
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'email',
          dataIndex: 'email',
          width: 120,
          align: 'center'
        },

        {
          title: 'avatar',
          dataIndex: 'avatar',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'img' }
        },
        {
          title: 'sex',
          dataIndex: 'sex',
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
            if(value == 1){
              return "man"
            }else if(value == 2){
              return "girl"
            }
            return "secrecy"
          }
        },
        {
          title: 'birthday',
          dataIndex: 'birthday',
          ellipsis: true,
          align: 'center'
        },

        {
          title: 'status',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'createTime',
          dataIndex: 'createTime',
          // scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'operation',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询用户信息列表 */
    getList () {
      this.loading = true
     pageUser(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        mobile: undefined,
        email: undefined,
        nickname: undefined,
        sex: undefined,
        invitationCode: undefined,
        isNotice: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: 'Confirm to delete the selected data?',
        content: 'The current selected number is ' + ids + ' data',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delUser(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                'successfully delete',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    handleSetLineVip(value,row){
      var that = this
      console.log("row==========",row)
      const ids =  this.ids
      this.$confirm({

        title: value == 1 ? 'Confirm setting the selected data' : 'Confirm to cancel the selected data',
        content: 'The current selected number is ' + ids + ' data',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          if(value == 1){
            return setLineVip(data)
              .then(() => {
                that.onSelectChange([], [])
                that.getList()
                that.$message.success(
                  'successfully set',
                  3
                )
              })
          }else{
            return unSetLineVip(data)
              .then(() => {
                that.onSelectChange([], [])
                that.getList()
                that.$message.success(
                  'successfully set',
                  3
                )
              })
          }

        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    handleSetHighQuality(value,row){
      var that = this
      console.log("row==========",row)
      const ids =  this.ids

      this.$confirm({
        title: value == 1 ? 'Confirm setting the selected data' : 'Confirm to cancel the selected data',
        content: 'The current selected number is ' + ids + ' data',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          if(value == 1){
            return setHighQuality(data)
              .then(() => {
                that.onSelectChange([], [])
                that.getList()
                that.$message.success(
                  'successfully set',
                  3
                )
              })
          }else{
            return unSetHighQuality(data)
              .then(() => {
                that.onSelectChange([], [])
                that.getList()
                that.$message.success(
                  'successfully set',
                  3
                )
              })
          }

        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: 'Do you confirm export?',
        content: 'This operation exports all data under the current condition instead of the selected data',
        onOk () {
          that.download('biz/user/export', {
            ...that.queryParam
          }, `userinfo_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    handleDetail(rowData){
      this.$refs.userInfo.initValue(rowData);
      this.$refs.userInfo.title = "details";
      this.$refs.userInfo.visible = true;
    },
  }
}
</script>
