<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">

      <a-form-model-item label="位置类型" prop='bannerType' :required="true">
        <a-select placeholder="请选择" v-model="form.bannerType" >
          <a-select-option :value="1">INDEX</a-select-option>
<!--          <a-select-option :value="2">消息</a-select-option>-->
<!--          <a-select-option :value="3">分享海报</a-select-option>-->
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="轮播图" prop="bannerUrl">
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          v-model="form.bannerUrl"
          :showUploadList="false"
          :beforeUpload='(info) => fileBeforeUpload(info)'
          :customRequest='(info) => fileUploadRequest(info)'
        >
          <!--
                      @change="handleChange"-->
          <img v-if="form.bannerUrl" :src="form.bannerUrl" alt="logo"
               style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>

      <a-form-model-item label="排序" prop="sort">
        <a-input v-model="form.sort" placeholder="请输入排序"/>
      </a-form-model-item>

      <a-form-model-item  label="跳转类型" prop="type" v-if='form.bannerType == 1 || form.bannerType == 2'>
        <a-select placeholder="请选择跳转类型" @change="updateType" v-model="form.type">
          <a-select-option :key="0">不跳转</a-select-option>
<!--          <a-select-option :key="1">图文</a-select-option>-->
          <a-select-option :key="2">外链</a-select-option>
<!--          <a-select-option :key="3">用户主页</a-select-option>-->
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="form.type==1" label="内容" prop="content">
        <editor v-model="form.content" v-if="form.type == 1" />
      </a-form-model-item>
      <a-form-model-item v-if="form.type==2" label="内容" prop="content">
        <a-input v-model="form.content" v-if="form.type == 2" />
      </a-form-model-item>

      <a-form-model-item v-if="form.type==3" label="用户" prop="userId">
        <a-select
          v-model="form.userId"
          showSearch
          style="width: 100%"
          placeholder="请选择用户"
          allow-clear
          optionFilterProp="children"
        >
          <a-select-option :value="item.id" :key="item.id" :title="item.nickname" v-for="(item,index) in listAll">
            {{ item.nickname }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {getBanner, addBanner, updateBanner} from '@/api/banner/banner'
import {listUser} from "@/api/biz/user";
import UploadFileToOSS from '@/utils/upload-file-to-oss'
// import UploadFileToVOD from "@/api/vod.js"
import Editor from '@/components/Editor'
export default {
  name: 'CreateForm',
  props: {},
  components: { Editor },
  data() {
    return {
      submitLoading: false,
      uploadLoading: false,
      formTitle: '',
      type: 0,
      listAll: [],
      isJup: false,
      // 表单参数
      form: {
        id: null,

        sort: null,
        bannerType:null,
        userId: null,

        content: null,
        type: null,

        bannerUrl: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        sort: [
          {required: true, message: '排序不能为空', trigger: 'blur'}
        ],

        bannerType: [
          {required: true, message: '位置类型不能为空', trigger: 'blur'}
        ],
        type: [
          {required: true, message: '跳转类型不能为空', trigger: 'blur'}
        ],
        content: [
          {required: true, message: '图文内容不能为空', trigger: 'blur'}
        ],
        linkUrl: [
          {required: true, message: '外部链接不能为空', trigger: 'blur'}
        ],
        bannerUrl: [
          {required: true, message: '轮播图url不能为空', trigger: 'blur'}
        ]
      }
    }
  },
  filters: {},
  created() {
    this.getUser();
  },
  computed: {},
  watch: {},
  mounted() {
  },

  methods: {

    updateType(value) {
      this.type=value
    },
    /**
     * 文件上传前校验
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileBeforeUpload(fileInfo) {
      // 图片类型字段, 进行文件类型判断
      if (!fileInfo.type.includes('image')) {
        this.$message.warning('请上传图片');
        return false;
      }
      return true;
    },
    /**
     * 文件上传请求自定义
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileUploadRequest(fileInfo) {

      this.loading = true;
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {

          this.loading = false;
          this.form.bannerUrl = res[0].url;
        },
      });
    },
    onClose() {
      this.isJup = false
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    getUser() {
      listUser({}).then(res => {
        this.listAll = res.data;
      })
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,

        sort: null,

        userId: null,

        content: null,
        type: null,

        bannerUrl: null,

        createTime: null,

        remark: null,
      }
      // this.$nextTick(() => {
      //   if(this.$refs.form){
      //     this.$refs.form.resetFields()
      //   }
      // })
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getBanner({"id": id}).then(response => {
        this.form = response.data
        this.isJup = this.form.isJump
        this.type=this.form.type
        this.open = true
        this.formTitle = '修改'
      })

    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateBanner(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addBanner(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
