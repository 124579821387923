<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :destroyOnClose="true">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="previous menu" prop="parentId">
        <a-tree-select v-model="form.parentId" style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="menuOptions" placeholder="please enter"
          :replaceFields="{ children: 'children', title: 'menuName', key: 'id', value: 'id' }"
          tree-default-expand-all>
        </a-tree-select>
      </a-form-model-item>
      <a-form-model-item label="Menu type" prop="menuType">
        <a-radio-group v-model="form.menuType" button-style="solid">
          <a-radio-button value="M">catalogue</a-radio-button>
          <a-radio-button value="C">menu</a-radio-button>
          <a-radio-button value="F">button</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="icon" prop="icon" v-if="form.menuType != 'F'">
        <a-space size="large">
          <a-icon :component="allIcon[form.icon + 'Icon']" v-if="form.icon && allIcon[form.icon + 'Icon']" />
          <a-icon :type="form.icon" v-if="form.icon && !allIcon[form.icon + 'Icon']" />
          <a-button type="dashed" @click="selectIcon">
            Select icon
          </a-button>
          <a @click="cancelSelectIcon" v-if="iconVisible" style="margin-left: 8px">
            pack up
            <a-icon type="up" />
          </a>
        </a-space>
      </a-form-model-item>
      <a-card :body-style="{ padding: '10px 20px' }" :bordered="false" v-if="iconVisible">
        <icon-selector v-model="form.icon" @change="handleIconChange" :svgIcons="iconList" :allIcon="allIcon" />
      </a-card>
      <a-form-model-item label="Menu name" prop="menuName">
        <a-input v-model="form.menuName" placeholder="please enter" />
      </a-form-model-item>
      <a-form-model-item label="sort" prop="orderNum">
        <a-input-number v-model="form.orderNum" :min="0" :max="9999" style="width: 100%" />
      </a-form-model-item>
      <a-form-model-item prop="isFrame" v-if="form.menuType != 'F'">
        <span slot="label">
          Outside chain or not
          <a-tooltip>
            <template slot="title">
              If external link is selected, the routing address needs to start with 'http(s)://'
            </template>
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-radio-group v-model="form.isFrame" button-style="solid">
          <a-radio-button v-for="(d, index) in isFrameOptions" :key="index" :value="parseInt(d.value)">{{ d.label
            }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item prop="path" v-if="form.menuType != 'F'">
        <span slot="label">
          Routing address
          <a-tooltip>
            <template slot="title">
              The routing address of the access, such as: 'user', if the external network address requires internal link access, start with 'http(s)://'
            </template>
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-input v-model="form.path" placeholder="please enter" />
      </a-form-model-item>
      <a-form-model-item prop="component" v-if="form.menuType == 'C'">
        <span slot="label">
          Component path
          <a-tooltip>
            <template slot="title">
              The access component path, such as' system/user/index ', defaults to the 'views' directory
            </template>
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-input v-model="form.component" placeholder="please enter" />
      </a-form-model-item>
      <a-form-model-item prop="perms" v-if="form.menuType != 'M'">
        <span slot="label">
          Permission identification
          <a-tooltip>
            <template slot="title">
              Permission characters defined in the controller, such as: @PreAuthorize(' @ss.hasPermi('system:user:list') ')
            </template>
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-input v-model="form.perms" placeholder="please enter" :maxLength="100" />
      </a-form-model-item>
      <a-form-model-item prop="visible" v-if="form.menuType != 'F'">
        <span slot="label">
          Show or not
          <a-tooltip>
            <template slot="title">
              Select Hide and the route will not appear in the sidebar, but will still be accessible
            </template>
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-radio-group v-model="form.visible" button-style="solid">
          <a-radio-button v-for="(d, index) in visibleOptions" :key="index" :value="parseInt(d.value)">{{ d.label
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item prop="status" v-if="form.menuType != 'F'">
        <span slot="label">
          status
          <a-tooltip>
            <template slot="title">
              If disabled is selected, the route will not appear in the sidebar and cannot be accessed
            </template>
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-radio-group v-model="form.status" button-style="solid">
          <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="parseInt(d.value)">{{ d.label }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item prop="isCache" v-if="form.menuType == 'C'">
        <span slot="label">
          Cache or not
          <a-tooltip>
            <template slot="title">
              If yes is selected, it is cached by 'keep-alive', and the name and address of the matching component must be consistent. It is valid only on multiple tabs.
            </template>
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-radio-group v-model="form.isCache" button-style="solid">
          <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="parseInt(d.value)">{{ d.label }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            save
          </a-button>
          <a-button type="dashed" @click="cancel">
            cancel
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>

import { getMenu, addMenu, updateMenu } from '@/api/system/menu'
import allIcon from '@/core/icons'
import icons from '@/utils/requireIcons'
import IconSelector from '@/components/IconSelector'

export default {
  name: 'CreateForm',
  props: {
    statusOptions: {
      type: Array,
      required: true
    },
    visibleOptions: {
      type: Array,
      required: true
    },
    menuOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    IconSelector
  },
  data() {
    return {
      isFrameOptions:[{value:1,label:"yes"},{value:0,label:"no"}],
      isCacheOptions:[{value:1,label:"cache"},{value:0,label:"no cache"}],
      allIcon,
      iconVisible: false,
      iconList: icons,
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: undefined,
        parentId: "0",
        menuName: undefined,
        icon: undefined,
        menuType: 'M',
        orderNum: undefined,
        isFrame: 0,
        isCache: 1,
        visible: 1,
        status: 1
      },
      open: false,
      rules: {
        menuName: [{ required: true, message: 'The menu name cannot be empty', trigger: 'blur' }],
        orderNum: [{ required: true, message: 'The menu order cannot be empty', trigger: 'blur' }],
        path: [{ required: true, message: 'The route address cannot be empty', trigger: 'blur' }],
        component: [{ required: true, message: 'The component path cannot be empty', trigger: 'blur' }]

      }
    }
  },
  filters: {
  },
  created() {
  },
  computed: {
  },
  watch: {
    'form.menuType': {
      handler(newVal) {
        if (newVal) {

        }
      },
      // deep: true
    }
  },
  methods: {
    changeMenutype() {

    },
    filterIcons() {
      this.iconList = icons
      if (this.name) {
        this.iconList = this.iconList.filter(item => item.includes(this.name))
      }
    },
    onClose() {
      this.open = false
      this.iconVisible = false
    },
    hideIconSelect() {
      this.iconList = icons
      this.iconVisible = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.iconVisible = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        parentId: "0",
        menuName: undefined,
        icon: undefined,
        menuType: 'M',
        orderNum: undefined,
        isFrame: 0,
        isCache: 1,
        visible: 1,
        status: 1
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.$emit('select-tree')
      if (row != null && row.id) {
        this.form.parentId = row.id
      } else {
        this.form.parentId = "0"
      }
      this.open = true
      this.formTitle = 'Add menu'
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset()
      this.$emit('select-tree')
      getMenu(row.id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = 'Modify menu'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      console.log('data validation', this.$refs.form.validate)
      this.$refs.form.validate(valid => {
        console.log('valid', valid)
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined) {
            updateMenu(this.form).then(response => {
              this.$message.success(
                'modify successfully',
                3
              )
              this.open = false
              this.iconVisible = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addMenu(this.form).then(response => {
              this.$message.success(
                'success',
                3
              )
              this.open = false
              this.iconVisible = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    handleIconChange(icon) {
      this.iconVisible = false
      this.form.icon = icon
    },
    changeIcon(type) {
      this.currentSelectedIcon = type
    },
    selectIcon() {
      this.iconVisible = !this.iconVisible
    },
    cancelSelectIcon() {
      this.iconVisible = false
    }
  }
}
</script>
