<template>
  <a-drawer width="45%" :label-col="4" :wrapper-col="14" :visible="visible" @close="handleCancel">
    <a-tabs default-active-key="1"  >
      <a-tab-pane key="1" tab="authentication information">
        <detail-list :col="2">
          <detail-list-item term="userName">{{model.userName}}</detail-list-item>
          <detail-list-item term="mobile">{{ model.phone}}</detail-list-item>
          <detail-list-item term="Type of certified master"><a-tag    color="green">{{model.type | typeFilter  }}</a-tag></detail-list-item>
          <detail-list-item term="User ID photo">
            <template v-if="model.userImg">
              <img-preview   :src="model.userImg" :size="60"  style="margin-right: 8px;" />
            </template>
            <template v-else>未上传</template>


          </detail-list-item>
          <detail-list-item term="manufacturer">{{ model.manufacturer}}</detail-list-item>
          <detail-list-item term="Vehicle name">{{ model.carName}}</detail-list-item>
          <detail-list-item term="model of car">{{ model.carModel}}</detail-list-item>
          <detail-list-item term="Vehicle color">{{ model.carColor}}</detail-list-item>
          <detail-list-item term="Vehicle years (years)"> {{model.carLife }} </detail-list-item>
          <detail-list-item  term="vehicle type"><a-tag    color="red">{{model.carType  | carTypeFilter}}</a-tag></detail-list-item>
          <detail-list-item term="Body structure (seat number)">{{ model.carStructure}}</detail-list-item>
          <detail-list-item term="license plate number">{{ model.carPlate}}</detail-list-item>
          <detail-list-item term="Vehicle home">{{ model.carHomeLocation}}</detail-list-item>
          <detail-list-item term="Number of seat belts (PCS)">{{ model.safetyBeltNum}}</detail-list-item>

          <detail-list-item term="Front of driver's license">
            <template v-if="model.drivingLicenceFront">
              <img-preview   :src="model.drivingLicenceFront" :size="60"  style="margin-right: 8px;" />
            </template>
            <template v-else>No upload</template>

          </detail-list-item>
          <detail-list-item term="Back of driver's license">
            <template v-if="model.drivingLicenceReverse">
              <img-preview   :src="model.drivingLicenceReverse" :size="60"  style="margin-right: 8px;" />
            </template>
            <template v-else>No upload</template>

          </detail-list-item>
          <detail-list-item term="Inside the car">
            <template v-if="model.insideTheCar">
              <img-preview v-for="item in model.insideTheCar.split(',')" :src="item" :size="60"
                           style="margin-right: 8px;" />
            </template>
            <template v-else>No upload</template>


          </detail-list-item>
          <detail-list-item term="Vehicle photo">
            <template v-if="model.carPicture">
              <img-preview v-for="item in model.carPicture.split(',')" :src="item" :size="60"
                           style="margin-right: 8px;" />
            </template>
            <template v-else>No upload</template>


          </detail-list-item>
          <detail-list-item term="automobile insurance">
            <template v-if="model.automobileInsurance">
              <img-preview v-for="item in model.automobileInsurance.split(',')" :src="item" :size="60"
                           style="margin-right: 8px;" />
            </template>
            <template v-else>No upload</template>

          </detail-list-item>
          <detail-list-item term="Vehicle safety inspection">
            <template v-if="model.vehicleSafetyInspection">
              <img-preview v-for="item in model.vehicleSafetyInspection.split(',')" :src="item" :size="60"
                           style="margin-right: 8px;" />
            </template>
            <template v-else>No upload</template>

          </detail-list-item>
          <detail-list-item term="Auto safety inspection authorized background check results">
            <a-tag    color="green">
              {{model.isAuthorization == 1 ? "agree":"disagree"}}
            </a-tag>
          </detail-list-item>
          <detail-list-item v-if='model.isAuthorization == 1' term="Background investigation consent form">{{ model.letterOfConsent}}</detail-list-item>

          <detail-list-item term="detailed address">{{ model.address}}</detail-list-item>


          <detail-list-item term="audit status"><a-tag    color="green">{{model.status == 0 ? "To be reviewed": (model.status == 1 ? "pass": "not pass")}}</a-tag></detail-list-item>
          <detail-list-item v-if='model.status == 2' term="the reason for refusal">{{ model.refusalCause}}</detail-list-item>
          <detail-list-item term="Online or not"><a-tag    color="green">{{model.isOnline == 1 ? "yes": "no"}}</a-tag></detail-list-item>

        </detail-list>

      </a-tab-pane>


    </a-tabs>
  </a-drawer>




</template>

<script>

  import DetailList from '@/components/tools/DetailList'
  import ImgPreview from "@/components/crud/table/ImgPreview.vue";
  import dictTag from '@/components/DictTag/index2.vue'
  const DetailListItem = DetailList.Item
  import { getUserCar } from '@/api/userCar/userCar'
  import { detailByUserId } from '@/api/user/standardInfo'
  import Template from '@/views/sms/template/index.vue'
  export default {
    name: "HouseInfo",
    components: {
      Template,
      ImgPreview,
      DetailList,dictTag,
      DetailListItem
    },

    data () {
      return {
        title:'',
        width:1500,
        visible: false,
        disableSubmit: false,
        lessorShow: false,
        lesseeShow: false,

        query: {},
        model: { },



      }
    },
    created() {

    },
    filters: {
      typeFilter(type) {
        let str = ''
        switch (type) {
          case 1: str = 'Economy';
            break;
          case 2: str = 'ushare';
            break;
          case 3: str = 'special train';
            break;
          case 4: str = 'Unmanned';
            break;
          default: str = ''
        }
        return str
      },
      carTypeFilter(carType){
        let str = ''
        switch (carType) {
          case 1: str = 'small car';
            break;
          case 2: str = 'in-between car';
            break;
          case 3: str = 'large car';
            break;
          case 4: str = 'sports car';
            break;
          case 5: str = 'SUVk';
            break;
          default: str = ''
        }
        return str
      }
    },
    methods: {
      initValue(record){
        this.model = record

      },

      close () {
        this.$emit('close');
        this.visible = false;
      },
      handleCancel () {
        this.close()
      },
      back(){
        this.$router.go(-1)
      },
    },
  }
</script>

<style lang="less" scoped>

  .detail-layout {
    margin-left: 44px;
  }
  .text {
    color: rgba(0, 0, 0, .45);
  }

  .heading {
    color: rgba(0, 0, 0, .85);
    font-size: 20px;
  }

  .no-data {
    color: rgba(0, 0, 0, .25);
    text-align: center;
    line-height: 64px;
    font-size: 16px;

    i {
      font-size: 24px;
      margin-right: 16px;
      position: relative;
      top: 3px;
    }
  }

  .mobile {
    .detail-layout {
      margin-left: unset;
    }
    .text {

    }
    .status-list {
      text-align: left;
    }
  }
</style>