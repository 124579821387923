<!-- 用户状态标签 -->
<template>
  <div>
    <crud ref="crud" :config="config"  @audit="audit" @auditAll="auditAll" @detailUserCar="detailSeed"></crud>
    <approve-modal ref='approveModal' @close='auditClose'/>
    <user-car-info ref='userCardInfo' @close='auditClose'/>
  </div>
</template>


<script>
import Crud from '@/components/crud/Crud'
import UserCarInfo from '@/views/userCar/modules/UserCarInfo.vue'
import ApproveModal from '@/views/userCar/modules/ApproveModal.vue'
import { updateUserCar,approveAll } from '@/api/userCar/userCar'

export default {
  name: "index",
  components: {
    Crud,ApproveModal,UserCarInfo
  },
  data() {
    return {
      config: {
        search: [
          { field: 'userName', name: 'userName' },
          { field: 'phone', name: 'mobile' },
        ],
        button: [
          { name: 'One-click audit',  event: 'auditAll', color: 'primary' }
        ],
        table: {
          api: '/user/user-cars/page',
          deleteApi: '/user/user-cars/delete',
          col: [
            { type: 'index' },
            { field: 'userName', name: 'userName' },

            { field: 'type', name: 'type' ,type: 'tag',
              tags: [{ name: '', }, { name: 'Economy', color: 'green' }, { name: 'ushare', color: 'pink' },
                { name: 'special train', color: 'red' }, { name: 'Unmanned', color: 'purple' },]},
            { field: 'phone', name: 'mobile',   },
            { field: 'userImg',  name: 'User ID photo',type: 'img', },
            { field: 'carName', name: 'Vehicle name', },
            { field: 'carModel', name: 'model of car', },
            { field: 'carType', name: 'vehicle type' ,type: 'tag',
              tags: [{ name: '', }, { name: 'small car', color: 'pink' }, { name: 'in-between car', color: 'green' },
                { name: 'large car', color: 'red' }, { name: 'sports car', color: 'purple' }, { name: 'SUVk', color: 'orange' }]},
            { field: 'createTime', name: 'createTime'  },
            {
              type: 'button', button: [
                { name: 'detail', event: 'detailUserCar' },
                { name: 'audit', event: 'audit' , checkShow: (rowData) => rowData.status == 0 },
                // { name: '删除', event: 'delete', checkShow: (row) => !(row.children && row.children.length) }
              ]
            },
          ]
        },
        // form: {
        //   addApi: `/user/user-cars/add`,
        //   editApi: `/user/user-cars/edit`,
        //   item: [
        //     { field: 'name', name: '名称', required: true },
        //     { field: 'pid', name: '上级', type: 'select', api: '/dynamic/dynamic-graph-category/selectList', reload: true },
        //     { field: 'sort', name: '序号', type: 'number', min: 0 },
        //   ]
        // }
      },
    }
  },
  methods: {
    audit(button, rowData) {
      this.$refs.approveModal.audit(rowData);
    },
    auditAll(){
      console.log("======一键审核====")
      let that = this;
      this.$confirm({
        title: 'Review and confirm',
        content: 'Confirm all one-click audit?',
        okText: 'affirm',
        cancelText: 'cancel',
        okType: 'danger',
        onOk: () => {
          approveAll( {}).then((res) => {
            if (res.success) {
              that.$message.success(res.message);
            } else {
              that.$message.warning(res.message)
            }
          }).finally(() => {
            this.$refs.crud.getList();
            that.confirmLoading = false

          })
        },
      });

    },
    auditClose() {
      this.$refs.crud.getList();
    },
    detailSeed(button, rowData){
      this.$refs.userCardInfo.initValue(rowData);
      this.$refs.userCardInfo.title = "detail";
      this.$refs.userCardInfo.visible = true;
    },
  }

}
</script>