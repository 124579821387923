<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="dictName">
                <a-input v-model="queryParam.dictName" placeholder="Please enter a dictionary name" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="dictType">
                <a-input v-model="queryParam.dictType" placeholder="Please enter the dictionary type" allow-clear />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="status">
                  <a-select placeholder="status" v-model="queryParam.status" style="width: 100%">
                    <a-select-option v-for="(d, index) in dict.type['sys_enable_disable']" :key="index"
                      :value="d.value">{{ d.label }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                :style="advanced && { float: 'right', overflow: 'hidden' } || {}">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />query</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />reset</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? 'pack up' : 'unfold' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['system:dict:add']">
          <a-icon type="plus" />add
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)"
          v-hasPermi="['system:dict:edit']">
          <a-icon type="edit" />edit
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['system:dict:remove']">
          <a-icon type="delete" />delete
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['system:dict:export']">
          <a-icon type="download" />export
        </a-button>
        <a-button type="dashed" :loading="refreshing" @click="handleRefreshCache" v-hasPermi="['system:dict:remove']">
          <a-icon type="redo" />Refresh cache
        </a-button>
        <table-setting :style="{ float: 'right' }" :table-size.sync="tableSize" v-model="columns"
          :refresh-loading="loading" @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form ref="createForm" :statusOptions="dict.type['sys_enable_disable']" @ok="getList" />
      <!-- 数据展示 -->
      <a-table :loading="loading" :size="tableSize" rowKey="id" :columns="columns" :expandedRowKeys="expandedKeys"
        @expand="onExpandCurrent" :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :pagination="false"
        :bordered="tableBordered">
        <span slot="expandedRowRender" slot-scope="text">
          <dict-data ref="dictData" :dictId="text.id" :dictType="text.dictType" />
        </span>
        <span slot="status" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_enable_disable']" :value="record.status" />
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['system:dict:edit']">
            <a-icon type="edit" />edit
          </a>
          <a-divider type="vertical" />
          <a @click="handleDelete(record)" v-hasPermi="['system:dict:remove']">
            <a-icon type="delete" />delete
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParam.pageNum"
        :total="total" :page-size="queryParam.pageSize" :showTotal="total => `common ${total} strip`"
        @showSizeChange="onShowSizeChange" @change="changeSize" />
    </a-card>
  </page-header-wrapper>
</template>

<script>

import { pageType, delType, refreshCache } from '@/api/system/dict/type'
import CreateForm from './modules/CreateForm'
import DictData from './modules/DictData'
import CreateDataForm from './modules/CreateDataForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Dict',
  components: {
    CreateForm,
    DictData,
    CreateDataForm
  },
  mixins: [tableMixin],
  dicts: ['sys_enable_disable'],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      refreshing: false,
      total: 0,
      // 日期范围
      dateRange: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        dictName: undefined,
        dictType: undefined,
        status: undefined
      },
      expandedKeys: [],
      columns: [
        {
          title: 'id',
          dataIndex: 'id',
          align: 'center'
        },
        {
          title: 'dictName',
          dataIndex: 'dictName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'dictType',
          dataIndex: 'dictType',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'status',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          align: 'center'
        },
        {
          title: 'remark',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'createTime',
          dataIndex: 'createTime',
          ellipsis: true,
          scopedSlots: { customRender: 'createTime' },
          align: 'center'
        },
        {
          title: 'operation',
          dataIndex: 'operation',
          width: '15%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created() {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询字典列表 */
    getList() {
      this.loading = true
      pageType(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      }
      )
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = []
      this.queryParam = {
        pageNum: 1,
        pageSize: 10,
        dictName: undefined,
        dictType: undefined,
        status: undefined
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const dictIds = row.id || this.ids
      this.$confirm({
        title: 'Confirm to delete the selected data?',
        content: 'The currently selected dictionary number is ' + dictIds + ' data',
        onOk() {
          delType(dictIds).then((res) => {
            if (res.success) {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success('success',3)
            } else {
              that.$message.error(res.message)
            }
          }).catch(err => {console.log('err', err)
            that.$message.error(err + 'error', 3)
          })
        },
        onCancel() { }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: 'Do you confirm export?',
        content: 'This operation exports all data under the current condition instead of the selected data',
        onOk() {
          that.download('system/dict/type/export', {
            ...that.queryParam
          }, `type_${new Date().getTime()}.xlsx`)
        },
        onCancel() { }
      })
    },
    /** 清理缓存按钮操作 */
    handleRefreshCache() {
      this.refreshing = true
      refreshCache().then(() => {
        this.$message.success('success')
        this.$store.dispatch('dict/cleanDict')
      }).finally(() => {
        this.refreshing = false
      })
    },
    onExpandCurrent(expandedKeys, row) {
      if (expandedKeys) {
        this.expandedKeys = [row.id]
      } else {
        this.expandedKeys = []
      }
    }
  }
}
</script>
